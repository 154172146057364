footer {
  .copyright {
    margin-left: 15%;
  }
}

header {
  justify-content: space-between;
  .logo {
    display: none;
  }
  h1 {
    width: 40%;
  }
  nav {
    width:40%;
    .back {
      right: -30%;
      top: -42px;
    }
    .menu-button {
      margin-left: 60%;
    }
    ul {
      width: 250%;
      left: -150%;
      top:60px;
    }
  }
}

main {
  margin-top: 134px;
   .near {
     .nav-images {
       figure {
         width: 48%;
       }
     }
   }
 }